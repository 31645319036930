import { SyntheticEvent, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import useStore from "./store/store";
import { CMS_PATH } from "./App";
import { useNavigate } from "react-router-dom";

const Reset = () => {
  let navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [resetToken, setResetToken] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const setShowRegisterModal = useStore((state) => state.setShowRegisterModal);
  const { enqueueSnackbar } = useSnackbar();
  const [checkPasswordValid, setCheckLengthPassword] = useState<boolean>(false);
  const [checkDigitValid, setCheckDigit] = useState<boolean>(false);
  const [checkSpecialCharacterValid, setCheckSpecialCharacter] =
    useState<boolean>(false);
  const [checkUppercaseValid, setCheckUppercase] = useState<boolean>(false);
  const [enableButton, setEnableButton] = useState<boolean>(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const resetPasswordToken = query.get("token");

    if (resetPasswordToken !== null) {
      setResetToken(resetPasswordToken);
    }
  }, []);

  useEffect(() => {
    setEnableButton(
      checkLengthPassword(password) &&
        checkDigit(password) &&
        checkSpecialCharacter(password) &&
        checkUppercase(password) &&
        password === rePassword
    );
  }, [password, rePassword]);

  useEffect(() => {
    checkLengthPassword(password);
    checkDigit(password);
    checkSpecialCharacter(password);
    checkUppercase(password);
  }, [password]);

  function checkLengthPassword(password: string) {
    if (!password || password.length === 0 || password.length < 8) {
      setCheckLengthPassword(false);
      return false;
    }
    setCheckLengthPassword(true);
    return true;
  }

  function checkDigit(password: string) {
    if (/\d/.test(password)) {
      setCheckDigit(true);
      return true;
    }
    setCheckDigit(false);
    return false;
  }

  function checkSpecialCharacter(password: string) {
    if (/[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
      setCheckSpecialCharacter(true);
      return true;
    }
    setCheckSpecialCharacter(false);
    return false;
  }

  function checkUppercase(password: string) {
    console.log("check uppercase");
    // if (/[A-Z]/.test(password)) {
    //   return true;
    // }
    // return false;
    if (password !== password.toLowerCase()) {
      setCheckUppercase(true);
      return true;
    }
    setCheckUppercase(false);
    return false;
  }

  function checkFormValidity() {
    if (
      !checkLengthPassword(password) ||
      !checkDigit(password) ||
      !checkSpecialCharacter(password) ||
      !checkUppercase(password)
    ) {
      enqueueSnackbar(
        "La contraseña debe contener al menos 8 carácteres, 1 dígito, 1 carácter especial [!@#$%^&*()_+}{';'?>.<,], una mayúscula y una minúscula",
        {
          variant: "error",
        }
      );
    }
  }

  function changePassword(e: React.SyntheticEvent) {
    e.preventDefault();
    if (password !== rePassword) {
      enqueueSnackbar("Las contraseñas no coinciden", {
        variant: "error",
      });
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      fetch(CMS_PATH + "/api/password/reset", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          token: resetToken,
          password: password,
        }),
      }).then(async (response: Response) => {
        switch (response.status) {
          case 204:
            enqueueSnackbar("Contraseña cambiada correctamente", {
              variant: "success",
            });
            new URLSearchParams(window.location.search).delete("token");
            navigate("/");
            break;
          default:
            enqueueSnackbar("La contraseña no se ha podido cambiar", {
              variant: "error",
            });
            break;
        }
      });
    }

    return;
  }

  return (
    <div className="absolute w-full h-full bg-cover overflow-y-auto bg-no-repeat bg-[#F1F1F1] font-Campton">
      <main className="font-Staatliches">
        <div className="w-full h-full flex absolute items-center justify-end">
          <img className="w-[30%] mr-[15%]" src="./images/SVG/logoBeauty.svg" />
        </div>
        <div
          id="divFormLogin"
          className="w-full h-full md:h-auto md:w-1/4 left-0 md:left-[12%] absolute bg-white overflow-auto"
        >
          <div
            id="mainLogin"
            className="p-[14%] flex justify-center flex-col shadow-[0px_4px_12px_rgba(0,0,0,0.25)]"
          >
            <div className="flex flex-row gap-[10%]">
              <img
                className="object-contain w-[6rem] mx-auto"
                src="./images/SVG/logoBeauty.svg"
              />
            </div>
            <h1 className="mt-[8%] font-normal text-3xl tracking-widest leading-[40px] text-sandPrimaryBeauty">
              CAMBIA TU CONTRASEÑA
            </h1>
            <p className="font-OpenSans text-sandPrimaryBeauty text-base my-6">
              Sustituye la contraseña dada por una nueva para mejorar la
              seguridad de tu cuenta.
            </p>
            <form
              id="formLogin"
              onSubmit={changePassword}
              className="flex flex-col"
            >
              <div className="flex flex-col gap-2">
                <label
                  className="text-sm leading-5 text-sandPrimaryBeauty font-OpenSans"
                  htmlFor="password"
                >
                  Nueva contraseña
                </label>
                <div>
                  <input
                    className="w-full h-[2.8rem] rounded-md bg-[#e7e7e7] box-border border-2 border-[#969696] text-sm leading-5 text-[#00000080] px-2 font-OpenSans"
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Escribe la contraseña"
                    tabIndex={2}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <FontAwesomeIcon
                    onClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? faEye : faEyeSlash}
                    className="inline cursor-pointer -ml-9"
                  />
                </div>
                <small>
                  <ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400 font-OpenSans">
                    <li className="flex items-center">
                      {checkPasswordValid ? (
                        <svg
                          className="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className="w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      )}
                      La contraseña debe contener al menos 8 carácteres
                    </li>
                    <li className="flex items-center">
                      {checkDigitValid ? (
                        <svg
                          className="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className="w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      )}
                      1 dígito
                    </li>
                    <li className="flex items-center">
                      {checkSpecialCharacterValid ? (
                        <svg
                          className="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className="w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      )}
                      1 carácter especial {`[!@#$%^&*()_+}{';'?>.<,]`}
                    </li>
                    <li className="flex items-center">
                      {checkUppercaseValid ? (
                        <svg
                          className="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className="w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      )}
                      1 mayúscula y una minúscula
                    </li>
                  </ul>
                </small>
              </div>
              <div className="flex flex-col gap-2 mt-8">
                <label
                  className="text-sm leading-5 text-sandPrimaryBeauty font-OpenSans"
                  htmlFor="password"
                >
                  Repite la nueva contraseña
                </label>
                <div>
                  <input
                    className="w-full h-[2.8rem] rounded-md bg-[#e7e7e7] box-border border-2 border-[#969696] text-sm leading-5 text-[#00000080] px-2 font-OpenSans"
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Escribe la contraseña"
                    tabIndex={2}
                    required
                    onChange={(event) => setRePassword(event.target.value)}
                  />
                  <FontAwesomeIcon
                    onClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? faEye : faEyeSlash}
                    className="inline cursor-pointer -ml-9"
                  />
                </div>
              </div>
              <button
                id="buttonEnter"
                className={`mt-12 rounded-[50px] h-16 bg-sandPrimaryBeauty ${
                  enableButton ? "cursor-pointer" : "opacity-50"
                } text-white outline-none border-none text-2xl tracking-widest font-Staatliches leading-7`}
                style={{
                  filter: "drop-shadow(0px 4px 8px rgba(47, 59, 87, 0.3))",
                }}
                onClick={(ev) => {
                  if (enableButton) {
                    changePassword(ev);
                  } else {
                    checkFormValidity();
                  }
                }}
              >
                CAMBIAR
              </button>
            </form>
          </div>

          <footer className="relative w-full bg-sandPrimaryBeauty text-white md:hidden">
            <div className="text-center flex flex-col justify-center items-center gap-2">
              <a
                className="text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
                href="https://www.loreal.com/en/spain/pages/group/privacy-policy-spain/"
                target="_blank"
                rel="noopener noreferrer"
              >
                POLÍTICAS DE PRIVACIDAD
              </a>
              <a
                className="text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
                //@ts-ignore
                onClick={() => Optanon.ToggleInfoDisplay()}
              >
                POLÍTICA DE COOKIES
              </a>
            </div>
          </footer>
        </div>
        <footer className="absolute bottom-0 w-full p-3 bg-sandPrimaryBeauty text-white hidden md:block">
          <div className="text-center flex flex-row justify-end items-center gap-4">
            <a
              className="text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
              href="https://www.loreal.com/en/spain/pages/group/privacy-policy-spain/"
              target="_blank"
              rel="noopener noreferrer"
            >
              POLÍTICAS DE PRIVACIDAD
            </a>
            <a
              className="text-center md:text-left cursor-pointer text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
              //@ts-ignore
              onClick={() => Optanon.ToggleInfoDisplay()}
            >
              POLÍTICA DE COOKIES
            </a>
          </div>
        </footer>
      </main>
    </div>
  );
};
export default Reset;
