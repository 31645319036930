import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import useStore from "./store/store";
import { CMS_PATH } from "./App";
import { useNavigate } from "react-router-dom";

const Login = () => {
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [enableButton, setEnableButton] = useState<boolean>(false);
  const setShowRegisterModal = useStore((state) => state.setShowRegisterModal);
  const setShowPasswordLostModal = useStore(
    (state) => state.setShowPasswordLostModal
  );
  const { enqueueSnackbar } = useSnackbar();

  const VIRTUAL_SPACE_PATH = "./beauty";

  useEffect(() => {
    setEnableButton(email.length > 0 && password.length > 0);
  }, [email, password]);

  useEffect(() => {
    if (
      window.localStorage.getItem("refresh_token") &&
      window.localStorage.getItem("access_token")
    ) {
      fetch(
        CMS_PATH +
          `/api/refresh?access_token=${window.localStorage.getItem(
            "access_token"
          )}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            refresh_token: window.localStorage.getItem("refresh_token"),
          }),
          redirect: "follow",
        }
      )
        .then((value: Response) => {
          if (value.status === 401) {
            window.localStorage.removeItem("access_token");
            window.localStorage.removeItem("refresh_token");
          } else if (value.status === 200) {
            // The access token is correct
            value
              .json()
              .then(async (responseRefresh: any) => {
                let access_token = responseRefresh.access_token;
                let refresh_token = responseRefresh.refresh_token;
                window.localStorage.setItem("access_token", access_token);
                window.localStorage.setItem("refresh_token", refresh_token);
                window.open(VIRTUAL_SPACE_PATH, "_self");
              })
              .catch((reason) => {
                console.error(reason);
                return false;
              });
          }
        })
        .catch((reason: any) => {
          enqueueSnackbar("Ha habido algun problema, vuelva a intentarlo", {
            variant: "error",
          });
          return false;
        });
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const resetPasswordToken = query.get("token");

    if (resetPasswordToken !== null) {
      navigate("/reset");
    }
  }, []);

  async function login(e: React.SyntheticEvent) {
    e.preventDefault();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var body = JSON.stringify({
      email: (document.getElementById("email")! as HTMLInputElement).value,
      password: (document.getElementById("password")! as HTMLInputElement)
        .value,
    });
    let requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };

    fetch(CMS_PATH + "/api/login", requestOptions)
      .then(async (request: Response) => {
        if (request.status === 200) {
          let result = await request.json();
          let access_token = result.access_token;
          let refresh_token = result.refresh_token;
          window.localStorage.setItem("access_token", access_token);
          window.localStorage.setItem("refresh_token", refresh_token);
          window.open(VIRTUAL_SPACE_PATH, "_self");
        } else if (request.status === 403) {
          enqueueSnackbar(
            "¡Muchas gracias por registrarte en Beauty Campus! Próximamente recibirás un mail para conectarte al espacio virtual.",
            {
              variant: "warning",
            }
          );
        } else {
          enqueueSnackbar("Ha habido algun problema, vuelva a intentarlo", {
            variant: "error",
          });
        }
      })
      .catch((reason) => {
        enqueueSnackbar("Ha habido algun problema, vuelva a intentarlo", {
          variant: "error",
        });
      });

    return;
  }

  return (
    <div className="absolute w-full h-full bg-cover overflow-y-auto bg-no-repeat bg-[#F1F1F1] font-Campton ">
      <main className="font-Staatliches">
        <div className="w-full h-full flex absolute items-center justify-end">
          <img className="w-[30%] mr-[15%]" src="./images/SVG/logoBeauty.svg" />
        </div>
        <div
          id="divFormLogin"
          className="w-full h-full md:h-auto md:w-[30%] left-0 md:left-[12%] absolute bg-white overflow-auto"
        >
          <div className="mt-4 flex md:hidden flex-row justify-around items-center bg-white">
            <div className="flex flex-col">
              <img className="w-[9rem]" src="./images/loreal_logo.png" />
              <img
                className="w-[9rem] ml-3"
                src="./images/telefonica_logo.png"
              />
            </div>
            <img className="w-[6rem]" src="./images/SVG/logoBeauty.svg" />
          </div>
          <div className="mt-2 md:flex hidden flex-row justify-around items-center bg-white">
            <div className="flex flex-col">
              <img className="w-[12rem]" src="./images/SVG/logoBeauty.svg" />
            </div>
          </div>
          <div
            id="mainLogin"
            className="px-[14%] pb-[14%] md:pt-2 flex justify-center flex-col md:shadow-[0px_4px_0px_rgba(0,0,0,0.25)]"
          >
            <h1 className="mt-[8%] font-normal text-3xl tracking-widest leading-[50px] text-sandPrimaryBeauty">
              INICIA SESIÓN
            </h1>
            <form id="formLogin" className="mt-[10%] flex flex-col gap-12">
              <div className="flex flex-col gap-2">
                <label
                  className="text-sm leading-5 text-sandPrimaryBeauty font-OpenSans"
                  htmlFor="email"
                >
                  Correo electrónico
                </label>
                <input
                  className="w-full h-[2.8rem] rounded-md bg-[#e7e7e7] box-border border-2 border-[#969696] text-sm leading-5 text-[#00000080] px-2 font-OpenSans"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Tu email de acceso"
                  tabIndex={1}
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="text-sm leading-5 text-sandPrimaryBeauty font-OpenSans"
                  htmlFor="password"
                >
                  Contraseña
                </label>
                <div>
                  <input
                    className="w-full h-[2.8rem] rounded-md bg-[#e7e7e7] box-border border-2 border-[#969696] text-sm leading-5 text-[#00000080] px-2 font-OpenSans"
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Escribe la contraseña"
                    tabIndex={2}
                    onChange={(event) => setPassword(event.target.value)}
                    required
                  />
                  <FontAwesomeIcon
                    onClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? faEye : faEyeSlash}
                    className="inline cursor-pointer -ml-9"
                  />
                </div>
                <a
                  onClick={() => setShowPasswordLostModal(true)}
                  className="cursor-pointer hover:underline text-sm leading-5 text-sandPrimaryBeauty font-OpenSans"
                >
                  ¿Has olvidado la contraseña?
                </a>
              </div>
              <button
                id="buttonEnter"
                className={`rounded-[50px] h-16 bg-sandPrimaryBeauty ${
                  enableButton ? "" : "opacity-50"
                }  text-white outline-none border-none cursor-pointer text-2xl tracking-widest font-Staatliches leading-7`}
                style={{
                  filter: "drop-shadow(0px 4px 8px rgba(47, 59, 87, 0.3))",
                }}
                onClick={login}
              >
                ENTRAR
              </button>
              <div className="text-center font-OpenSans font-normal text-base">
                ¿Todavía no tienes cuenta?
              </div>
              <a
                id="registro"
                className="font-Staatliches font-normal text-2xl tracking-widest underline uppercase text-sandSecondaryBeauty border-none bg-none text-center cursor-pointer -mt-6 -mb-6"
                onClick={() => setShowRegisterModal(true)}
              >
                ¡REGÍSTRATE AHORA!
              </a>
              <h1 className="border-b-2 border-[#969696]"></h1>

              <div className="text-center -mt-4">
                <h4 className="font-OpenSans font-normal text-base leading-7 text-sandPrimaryBeauty mt-0 mb-4">
                  ¿Problemas para acceder?
                </h4>
                <a
                  className="font-Staatliches font-normal text-2xl leading-7 tracking-widest underline uppercase text-sandPrimaryBeauty mt-0 mb-4"
                  href="mailto:beautycampus@loreal.com"
                >
                  CONTACTA CON NOSOTROS
                </a>
              </div>
              <div className="flex flex-row gap-2 md:gap-10 justify-center items-center">
                <img
                  className="w-40 hidden md:flex"
                  src="./images/loreal_logo.png"
                />
                <img
                  className="w-48 hidden md:flex"
                  src="./images/telefonica_logo.png"
                />
              </div>
            </form>
          </div>

          <footer className="relative w-full bg-sandPrimaryBeauty text-white md:hidden">
            <div className="text-center h-auto py-2 flex flex-row justify-center items-center gap-2">
              <a
                className="text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
                href="./documents/privacidad.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                POLÍTICAS DE PRIVACIDAD
              </a>
              <p>·</p>
              <a
                className="text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
                target="_blank"
                rel="noopener noreferrer"
                href="./documents/cookies.pdf"
              >
                POLÍTICA DE COOKIES
              </a>
              <p>·</p>
              <a
                className="text-center md:text-left cursor-pointer text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
                target="_blank"
                href="./documents/terminos.pdf"
              >
                TÉRMINOS DE USO
              </a>
              <p>·</p>
              <a
                className="footer__link text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
                href="javascript:Optanon.ToggleInfoDisplay();"
              >
                CONFIGURACIÓN DE COOKIES
              </a>
            </div>
          </footer>
        </div>
        <footer className="fixed bottom-0 w-full p-3 bg-sandPrimaryBeauty text-white hidden md:block">
          <div className="text-center flex flex-row justify-end items-center gap-4">
            <a
              className="text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
              href="./documents/privacidad.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              POLÍTICAS DE PRIVACIDAD
            </a>
            <a
              className="text-center md:text-left cursor-pointer text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
              target="_blank"
              href="./documents/cookies.pdf"
            >
              POLÍTICA DE COOKIES
            </a>
            <a
              className="text-center md:text-left cursor-pointer text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
              target="_blank"
              href="./documents/terminos.pdf"
            >
              TÉRMINOS DE USO
            </a>
            <a
              className="footer__link text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
              href="javascript:Optanon.ToggleInfoDisplay();"
            >
              CONFIGURACIÓN DE COOKIES
            </a>
          </div>
        </footer>
      </main>
    </div>
  );
};
export default Login;
